import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import ebookIT from "../../content/assets/portada-ebook-consejos-trabajar-en-it.png"
import { Twitter, LinkedIn, GitHub } from "../components/social"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Gatsby Starter Personal Website"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />

        <Services>
          <div style={{ marginBottom: "4em" }}>
            <Service>
              I'm a Frontend Developer with experience also with Backend and
              Cloud. Most of my experience its being with React, React Native,
              NodeJs, and AWS Cloud Provider. I also help newbies as a Mentor
              through Coading Coach. Click the button below to know more.
            </Service>
            <CodingCoach
              href="https://mentors.codingcoach.io/?name=Cristian+Echeverria"
              target="blank"
            >
              <Button
                marginTop="35px"
                background="rgb(83, 72, 255) none repeat scroll 0% 0%;"
              >
                Coach
              </Button>
            </CodingCoach>
          </div>
        </Services>

        <About>
          <Title>About</Title>
          <Subtitle>
            <StaticQuery
              query={socialQuery}
              render={data => {
                const { social } = data.site.siteMetadata
                return (
                  <>
                    <Twitter twitter={social.twitter} />
                    <LinkedIn linkedIn={social.linkedIn} />
                    <GitHub gitHub={social.gitHub} />
                  </>
                )
              }}
            />
          </Subtitle>

          <AboutMe>
            <p>
              Im originally from Honduras. Currently I live in Gothenburg,
              Sweden with my wife and son. I work as a Frontend Developer for
              <a href="https://www.etraveligroup.com/"> Etraveli Group</a>.
            </p>
            <p>
              During my free time, I love to create new stuff and help other
              devs by writing books and tutorials. In my latest book I tell my
              story about how I moved to Sweden as a Frontend Developer and some
              advice on how to get a job in IT.
            </p>
            <p>
              <a href="https://gumroad.com/l/dmTCd">English version</a> |
              <a href="https://gumroad.com/l/QciNX"> Spanish version</a>
            </p>
          </AboutMe>

          <AboutMeImg
            src={ebookIT}
            alt="Consejos para trabajar en IT. En el extranjero"
          />
        </About>
        {/* <About>
          <Title>De Dev a Ops</Title>
          <Subtitle>Podcast</Subtitle>

          <AboutMe>
            <p>
              También te invito a que nos escuches en el podcast{" "}
              <strong>De Dev a Ops</strong>, disponible en diversas plataformas.
              Selecciona en qué plataforma deseas escucharnos dando clic aqui{" "}
              <a href="https://anchor.fm/dedevaops">Escuchar Podcast</a> .
            </p>
          </AboutMe>

          <AboutMeImg src={devAops} alt="Podcast De Dev a Ops" />
        </About> */}
        <Link to="/blog/">
          <Button
            marginTop="35px"
            background="rgb(83, 72, 255) none repeat scroll 0% 0%;"
          >
            Go to Blog
          </Button>
        </Link>
      </Layout>
    )
  }
}

const socialQuery = graphql`
  query SocialQuery {
    site {
      siteMetadata {
        social {
          twitter
          linkedIn
          gitHub
          podcast
        }
      }
    }
  }
`
const Services = styled.section`
  text-align: center;

  @media (min-width: 600px) {
    display: grid;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 400px) {
    margin-bottom: -3.5em;
  }
`

const Service = styled.div`
  max-width: 500;
  display: flex;
  justify-content: center;

  @media (min-width: 600px) {
    margin-left: 2em;
  }

  @media (min-width: 300px) {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
`

const CodingCoach = styled.a`
  display: flex;
  justify-content: center;
  @media (min-width: 300px) {
    padding-bottom: 1.5em;
  }
`

const ServiceTitle = styled.h2`
  font-weight: 300;
  @media (min-width: 400px) {
    font-weight: 100;
  }

  @media (min-width: 300px) {
    padding-top: 1.5em;
  }
`

const About = styled.section`
  padding: 1em 2em;
  max-width: 1000px;
  margin: 0 auto;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-template-areas:
      "title img"
      "subtitle img"
      "text img";
    grid-column-gap: 2em;
  }
`

const Title = styled.h2`
  grid-area: title;
  margin-bottom: 0.25em;
  font-size: 2.25rem;
`

const Subtitle = styled.p`
  margin: 0;
  font-size: 1.5rem;

  padding: 0.25em 1em;
  font-family: "Roboto Slab", serif;
  margin-bottom: 1em;

  @media (min-width: 600px) {
    grid-column: 1 / -1;
    grid-row: 1.5;
    position: relative;
    left: -1em;
    width: calc(100% + 2em);
    padding-left: 1em;
  }
`

const AboutMe = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`

const AboutMeImg = styled.img`
  box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
`

export default IndexPage
