const colors = {
  black: "#131415",
  white: "#fff",
  gray: "#fafafa",
  red: "#E74C3C",
  blue: "#5348ff",
  green: "#29B573",
}

export default colors
