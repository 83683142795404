import { createTheming } from "@callstack/react-theme-provider"
import { lighten } from "polished"
import colors from "../utils/colors"

const themes = {
  default: {
    themeName: "default",
    colors: {
      primary: colors.blue,
      text: colors.black,
      bodyBg: colors.gray,
      headerBg: colors.blue,
      link: colors.blue,
      ...colors,
    },
  },
  dark: {
    themeName: "dark",
    colors: {
      primary: lighten(0.05, colors.blue),
      text: colors.white,
      bodyBg: colors.black,
      headerBg: colors.black,
      link: lighten(0.05, colors.blue),
      ...colors,
    },
  },
}

const { ThemeProvider, withTheme, useTheme } = createTheming(themes.default)

export { ThemeProvider, withTheme, useTheme, themes, colors }
